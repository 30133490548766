import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <h1 style={{ color: 'white' }}>Under Construction</h1>
      <p style={{ color: 'white' }}>Vote Concluded. We're building on Base.</p>
    </div>
  );
}

export default App;
